
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../../home/banner.vue'
import Bottoms from '../../home/bottoms.vue'

export default {
    name: 'translate',
    components: {
        homeTitlepc,
        headerBanner,
        Bottoms
        
    },

    mounted () {
     
    },

    created(){
       

    },

    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
      

    },

    data () {
        return {
            config,
           
            
        }
    }
}
