var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"box"},[_c('headerBanner',{attrs:{"headerImg":require('./images/banner.png'),"title":"公证翻译","activeIndex":3,"topic":"支持多种材料翻译，语种齐全，公证处快速受理，翻译完成快递到家。","botname":"申请翻译"}}),_c('div',{staticClass:"plan"},[_c('homeTitlepc',{attrs:{"label":"应用场景"}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)],1),_c('div',{staticClass:"scene"},[_c('homeTitlepc',{attrs:{"label":"服务优势"}}),_vm._m(5)],1),_c('div',{staticClass:"course"},[_c('homeTitlepc',{attrs:{"label":"使用流程"}}),_vm._m(6)],1),_c('Bottoms',{attrs:{"bottomImg":require('./images/store.png'),"title":"公证翻译","topic":"让涉外公证办理更快更简单！","botname":"申请翻译"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"worth"},[_c('img',{attrs:{"src":require("./images/plan-a.png")}}),_c('div',{staticClass:"worth-info"},[_c('div',{staticClass:"tit"},[_vm._v("出国留学")]),_c('div',{staticClass:"desc"},[_vm._v("毕业证、学历、在学证明公证等")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"worth"},[_c('img',{attrs:{"src":require("./images/plan-b.png")}}),_c('div',{staticClass:"worth-info"},[_c('div',{staticClass:"tit"},[_vm._v("定居移民")]),_c('div',{staticClass:"desc"},[_vm._v("结婚证、护照、工作经历公证等")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"worth"},[_c('img',{attrs:{"src":require("./images/plan-c.png")}}),_c('div',{staticClass:"worth-info"},[_c('div',{staticClass:"tit"},[_vm._v("探亲旅游")]),_c('div',{staticClass:"desc"},[_vm._v("亲属关系、银行存款公证等")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"worth"},[_c('img',{attrs:{"src":require("./images/plan-d.png")}}),_c('div',{staticClass:"worth-info"},[_c('div',{staticClass:"tit"},[_vm._v("商务劳务")]),_c('div',{staticClass:"desc"},[_vm._v("派遣函、营业执照公证等")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"worth"},[_c('img',{attrs:{"src":require("./images/plan-e.png")}}),_c('div',{staticClass:"worth-info"},[_c('div',{staticClass:"tit"},[_vm._v("境外其它用途")]),_c('div',{staticClass:"desc"},[_vm._v("身份证、户籍注销公证等")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sections"},[_c('div',{staticClass:"part"},[_c('img',{attrs:{"src":require("./images/s1.png")}}),_c('div',{staticClass:"part-info"},[_c('div',{staticClass:"tit"},[_c('p',[_vm._v("随时随地申办")])])])]),_c('div',{staticClass:"part"},[_c('img',{attrs:{"src":require("./images/s2.png")}}),_c('div',{staticClass:"part-info"},[_c('div',{staticClass:"tit"},[_c('p',[_vm._v("公证处快速受理")]),_c('p',[_vm._v("翻译完成快递到家")])])])]),_c('div',{staticClass:"part"},[_c('img',{attrs:{"src":require("./images/s3.png")}}),_c('div',{staticClass:"part-info"},[_c('div',{staticClass:"tit"},[_c('p',[_vm._v("支持多种材料翻译")]),_c('p',[_vm._v("语种齐全")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"steps"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"stage"},[_c('img',{attrs:{"src":require("./images/step1.png")}}),_c('div',{staticClass:"num"},[_vm._v("01")])]),_c('div',{staticClass:"step-info"},[_vm._v("提交材料")])]),_c('img',{staticClass:"arrow",attrs:{"src":require("./images/arrow.png")}}),_c('div',{staticClass:"step"},[_c('div',{staticClass:"stage"},[_c('img',{attrs:{"src":require("./images/step2.png")}}),_c('div',{staticClass:"num"},[_vm._v("02")])]),_c('div',{staticClass:"step-info"},[_c('p',[_vm._v("公证处 ")]),_c('p',[_vm._v("审核受理")])])]),_c('img',{staticClass:"arrow1",attrs:{"src":require("./images/arrow.png")}}),_c('div',{staticClass:"step"},[_c('div',{staticClass:"stage"},[_c('img',{attrs:{"src":require("./images/step3.png")}}),_c('div',{staticClass:"num"},[_vm._v("03")])]),_c('div',{staticClass:"step-info"},[_vm._v("支付费用")])]),_c('img',{staticClass:"arrow2",attrs:{"src":require("./images/arrow.png")}}),_c('div',{staticClass:"step"},[_c('div',{staticClass:"stage"},[_c('img',{attrs:{"src":require("./images/step4.png")}}),_c('div',{staticClass:"num"},[_vm._v("04")])]),_c('div',{staticClass:"step-info"},[_c('p',[_vm._v("翻译完成")]),_c('p',[_vm._v("快递送达 ")])])])])
}]

export { render, staticRenderFns }